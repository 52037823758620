import '/assets/styles/pages/home.scss';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component'

// Sections
import MainSlider from '/views/sections/home-mainslider';

// Context
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

// Partials
import EmptyPlaceholder from '@wearetla/tla-essentials-tools/partials/empty-placeholder';
import LazyComponent from '@wearetla/tla-essentials-tools/partials/lazy-component';

// Services
import siteServices from '/services/site';

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';

const Offers = loadable(() => import('/views/sections/home-offers'));
const TopNav = loadable(() => import('/views/sections/home-topnav'));
const HorizontalBanners = loadable(() => import('/views/sections/home-horizontal-banners'));
const ProductCarousel = loadable(() => import('/views/sections/product-carousel'));
const FeaturedCategories = loadable(() => import('/views/sections/home-featured-categories'));

// Lazy Sections
const Ages = loadable(() => import('/views/sections/home-ages'), { ssr: false });
const Categories = loadable(() => (import('/views/sections/home-categories')), { ssr: false });
const TripleBanner = loadable(() => (import('/views/sections/home-triple-banner')), { ssr: false});
const BigBanner = loadable(() => (import('/views/sections/home-big-banner')), { ssr: false });
const Brands = loadable(() => (import('/views/sections/home-brands')), { ssr: false });
const Newborn = loadable(() => (import('/views/sections/home-newborn')), { ssr: false });
const SiteFeatures = loadable(() => (import('/views/sections/site-features')), { ssr: false });
const HomeSeoText = loadable(() => (import('/views/sections/home-seo-text')), { ssr: false });

const getMainSliderData = (mobile) => {
	return siteServices.getBanners(mobile ? 12 : 1);
}

const getMobileOfferBanners = () => {
	return siteServices.getBanners(14);
}

const getMobileHorizontalBanners = () => {
	return siteServices.getBanners(15);
}

const getBrands = () => {
	return siteServices.getFeaturedBrands();
}

const getTopTripleBannerData = () => {
	return siteServices.getBanners(3);
}

const getBottomTripleBannerData = () => {
	return siteServices.getBanners(4);
}

const getShowcase1 = () => {
	return siteServices.getShowcase(1);
}

// Async Fetchers
const showcase2Fetcher = () => {
	return new Promise((resolve) => {
		siteServices.getShowcase(2).then((data) => {
			resolve([
				{
					title: data ? data.title : 'En Çok Satan Ürünler',
					icon: 'heart-empty',
					key: 'interesting',
					products: data ? data.products : false,
				}
			]);
		}).catch(() => {
			resolve([]);
		})
	})
}

const showcase3Fetcher = () => {
	return new Promise((resolve) => {
		siteServices.getShowcase(3).then((data) => {
			resolve([
				{
					title: data ? data.title : 'İlginizi Çekebilecek Ürünler',
					icon: 'trend-up',
					key: 'top',
					products: data ? data.products : false,
				}
			]);
		}).catch(() => {
			resolve([]);
		})
	})
}

const Homepage = ({ pageProps }) => {
	const { mobile } = useBreakpoints();
	const { featuredCategories, isApplication } = useGlobalState();
	const _mounted = useRef(false);
	
	const [mainSliderData, setMainSliderData] = useState(pageProps?.mainSliderData ?? false);
	const [mobileOfferBannersData, setMobileOfferBannersData] = useState(pageProps?.mobileOfferBannersData ?? false);
	const [mobileHorizontalBannersData, setMobileHorizontalBannersData] = useState(pageProps?.mobileHorizontalBannersData ?? false);
	const [showcase1Data, setShowcase1Data] = useState(pageProps?.showcase1Data ?? false);
	
	useEffect(() => {
		_mounted.current = true;

		return(() => {
			_mounted.current = false;
		})
	}, [])

	useEffect(() => {
		if(!mainSliderData || (mainSliderData && mainSliderData.mobile !== mobile)) {
			setMainSliderData(false);
			getMainSliderData(mobile).then((banners) => {
				setMainSliderData({ banners, mobile });
			}, [])
		}
	}, [mainSliderData, mobile]);

	useEffect(() => {
		if(mobile && !mobileOfferBannersData) {
			getMobileOfferBanners().then(banners => {
				setMobileOfferBannersData(banners);
			})
		}
	}, [mobileOfferBannersData, mobile]);

	useEffect(() => {
		if(mobile && !mobileHorizontalBannersData) {
			getMobileHorizontalBanners().then(banners => {
				setMobileHorizontalBannersData(banners);
			})
		}
	}, [mobileHorizontalBannersData, mobile]);

	useEffect(() => {
		if(!showcase1Data) {
			getShowcase1().then((payload) => {
				if (_mounted.current) {
					setShowcase1Data(payload);
				}
			}).catch(() => {
			})
		}
	}, [showcase1Data]);

	return (
		<>
			{mobile &&
				<TopNav />
			}
			<MainSlider data={mainSliderData?.banners} />
			
			{mobile &&
				<>
					<Offers data={mobileOfferBannersData} />
					<HorizontalBanners data={mobileHorizontalBannersData}/>
				</>
			}

			<ProductCarousel
				id="home-showcase-1"
				className="home-showcase-1"
				data={[
					{
						title: showcase1Data ? showcase1Data.title : 'Kampanyalı Ürünler',
						icon: 'heart-empty',
						key: 'interesting',
						products: showcase1Data ? showcase1Data.products : false,
					}
				]} />

			{mobile &&
				<FeaturedCategories data={featuredCategories} />
			}

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '70rem' }} />
			}>
				<TripleBanner dataFetcher={getTopTripleBannerData} />
			</LazyComponent>

			<LazyComponent placeholder={
				<EmptyPlaceholder
					mobileStyles={{ 'height': '44.5rem' }}
					styles={{ 'height': '53.9rem' }} />
			}>
				<ProductCarousel
					id="home-showcase-2"
					className="home-showcase-2"
					dataFetcher={showcase2Fetcher} />
			</LazyComponent>

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '53.9rem' }} />
			}>
				<Ages />
			</LazyComponent>

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '61.3rem' }} />
			}>
				<Categories />
			</LazyComponent>

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '70rem' }} />
			}>
				<BigBanner />
			</LazyComponent>

			<LazyComponent placeholder={
				<EmptyPlaceholder
					mobileStyles={{ 'height': '44.5rem' }}
					styles={{ 'height': '53.9rem' }} />
			}>
				<ProductCarousel
					id="home-showcase-3"
					className="home-showcase-3"
					dataFetcher={showcase3Fetcher} />
			</LazyComponent>

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '70rem' }} />
			}>
				<TripleBanner pink dataFetcher={getBottomTripleBannerData} />
			</LazyComponent>

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '70rem' }} />
			}>
				<Brands dataFetcher={getBrands} />
			</LazyComponent>

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '70rem' }} />
			}>
				<Newborn />
			</LazyComponent>

			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '70rem' }} />
			}>
				<SiteFeatures />
			</LazyComponent>

			{!isApplication &&
				<LazyComponent placeholder={
					<EmptyPlaceholder
						styles={{ 'height': '70rem' }} />
				}>
					<HomeSeoText />
				</LazyComponent>
			}
		</>
	)
}

Homepage.propTypes = {
	pageProps: PropTypes.object
}

const returnEmptyPromise = () => {
	return new Promise((resolve) => {
		resolve(false);
	})
}

Homepage.getServerProps = ({ mediaStatus }) => {
	const isMobile = mediaStatus?.mobile ?? false;
	return new Promise((resolve) => {
		Promise.all([
			getMainSliderData(isMobile),
			isMobile ? getMobileOfferBanners() : returnEmptyPromise(),
			isMobile ? getMobileHorizontalBanners() : returnEmptyPromise(),
			getShowcase1(),
		]).then(([
			mainSliderData,
			mobileOfferBannersData,
			mobileHorizontalBannersData,
			showcase1Data,
		]) => {
			resolve({
				pageProps: {
					mainSliderData: { banners: mainSliderData, mobile: isMobile },
					mobileOfferBannersData,
					mobileHorizontalBannersData,
					showcase1Data,
				}
			});
		}).catch(() => {
			resolve({
				status: 500,
				notFound: true,
			})
		})
	});
}

export default Homepage